"use client";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./template/Header";
import axios from "axios";
import Swal from "sweetalert2";
import { FaCamera } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import SelfieUpload from "./SelfieUpload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Event = () => {
  const { event_id } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selfie, setSelfie] = useState(null);
  const [preview, setPreview] = useState(null);
  const [message, setMessage] = useState("");
  const [formVisible, setFormVisible] = useState(true);
  const [customFormData, setCustomFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [processingComplete, setProcessingComplete] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("us");

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          "https://api.uplup.com/hosts/fetch_event_form_submission.php",
          {
            params: { event_id },
          }
        );
        if (response.data.success) {
          setEventDetails(response.data.event);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to fetch event details.",
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching event details:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to fetch event details.",
        });
      }
    };

    fetchEventDetails();
  }, [event_id]);

  // Fetch user's country code based on IP address
  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get(
          `https://ipapi.co/json?api-key=6jdAohtEB3JM7CG4NqR6Nlhfezx1Kd9VCmO6WVSHesJ00FuzDw`
        );
        const countryCode = response.data.country_code.toLowerCase();
        setDefaultCountry(countryCode);
      } catch (error) {
        console.error("Failed to fetch country code based on IP:", error);
      }
    };

    fetchCountryCode();
  }, []);

  const handleFileChange = (e) => {
    setSelfie(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleCustomFieldChange = (fieldId, value) => {
    setCustomFormData({
      ...customFormData,
      [fieldId]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fullName || !email || !selfie) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all required fields and upload a selfie.",
      });
      return;
    }

    for (const field of JSON.parse(eventDetails.form_data)) {
      if (field.required && !customFormData[field.id]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Please fill in the required field: ${field.label}`,
        });
        return;
      }
    }

    const formData = new FormData();
    formData.append("event_id", event_id);
    formData.append("full_name", fullName);
    formData.append("email", email);
    formData.append("phone_number", phoneNumber);
    formData.append("selfie", selfie);
    formData.append("custom_form_data", JSON.stringify(customFormData));

    setLoading(true);
    setFormVisible(false);

    try {
      const response = await axios.post(
        "https://api.uplup.com/hosts/event_form_submission.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: false, // Ensure no cross-origin credential issues
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      setUploadProgress(100);
      console.log("response", response);

      if (response.data && response.data.message) {
        setProcessingComplete(true);
        setMessage(response.data.message);
      } else {
        setMessage(
          "There was an issue uploading your selfie. Please try finding a faster internet connection or try on another device. Thank you."
        );
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setMessage(
        "There was an error submitting the form. Please contact the host for assistance. " +
          error
      );
    }
  };

  useEffect(() => {
    if (uploadProgress === 100 && !processingComplete) {
      const interval = setInterval(() => {
        setProcessingProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 4;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [uploadProgress, processingComplete]);

  return (
    <div>
      <main className="py-10">
        <div className="px-12">
          <div className="max-w-md mx-auto mt-0">
            {eventDetails && (
              <div style={{ textAlign: "center" }}>
                <h1 className="text-2xl font-bold mb-4">
                  {eventDetails.event_title}
                </h1>
                <p
                  className="text-gray-700 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: eventDetails.event_description,
                  }}
                ></p>
              </div>
            )}

            <p className="text-gray-700 mb-4">
              Fill in the form below to download all of the photos that you were
              in at the event.
            </p>

            {message && (
              <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 mb-4">
                <p style={{ marginBottom: 0 }}>{message}</p>
              </div>
            )}

            {formVisible && (
              <form onSubmit={handleSubmit}>
                <SelfieUpload
                  preview={preview}
                  handleFileChange={handleFileChange}
                  setSelfie={setSelfie}
                  setPreview={setPreview}
                />
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Full Name *
                  </label>
                  <input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Email *
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Phone Number (optional)
                  </label>
                  <PhoneInput
                    country={defaultCountry}
                    value={phoneNumber}
                    onChange={(phone) => setPhoneNumber(phone)}
                    containerStyle={{ width: "100%" }}
                    inputStyle={{ width: "100%" }}
                    enableSearch={true}
                    required
                  />
                </div>
                {eventDetails?.form_data &&
                  JSON.parse(eventDetails.form_data).map((field, index) => (
                    <div className="mb-4" key={index}>
                      {field.type === "text" && (
                        <>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            {field.label}
                            {field.required && " *"}
                          </label>
                          <input
                            type="text"
                            value={customFormData[field.id] || ""}
                            onChange={(e) =>
                              handleCustomFieldChange(field.id, e.target.value)
                            }
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight                             focus:outline-none focus:shadow-outline"
                            required={field.required}
                          />
                        </>
                      )}
                      {field.type === "textarea" && (
                        <>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            {field.label}
                            {field.required && " *"}
                          </label>
                          <textarea
                            value={customFormData[field.id] || ""}
                            onChange={(e) =>
                              handleCustomFieldChange(field.id, e.target.value)
                            }
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required={field.required}
                          ></textarea>
                        </>
                      )}
                      {field.type === "checkbox" && (
                        <>
                          <label
                            className="flex items-center"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              checked={!!customFormData[field.id]}
                              onChange={(e) =>
                                handleCustomFieldChange(
                                  field.id,
                                  e.target.checked
                                )
                              }
                              required={field.required}
                              className="mr-4"
                              style={{
                                width: "inherit",
                                float: "left",
                                cursor: "pointer",
                              }}
                            />
                            <span>
                              {field.label}
                              {field.required && " *"}
                            </span>
                          </label>
                        </>
                      )}
                      {field.type === "radio" && (
                        <>
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            style={{ cursor: "pointer" }}
                          >
                            {field.label}
                            {field.required && " *"}
                          </label>
                          <div className="flex items-center">
                            {field.options.map((option, idx) => (
                              <div key={idx} className="flex items-center mr-4">
                                <input
                                  type="radio"
                                  className="mr-2 leading-tight"
                                  name={`radio-${field.id}`}
                                  value={option}
                                  checked={customFormData[field.id] === option}
                                  onChange={(e) =>
                                    handleCustomFieldChange(
                                      field.id,
                                      e.target.value
                                    )
                                  }
                                  required={field.required}
                                  style={{ cursor: "pointer" }}
                                />
                                <span>{option}</span>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      {field.type === "select" && (
                        <>
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            style={{ cursor: "pointer" }}
                          >
                            {field.label}
                            {field.required && " *"}
                          </label>
                          <select
                            onChange={(e) =>
                              handleCustomFieldChange(field.id, e.target.value)
                            }
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required={field.required}
                            style={{ cursor: "pointer" }}
                          >
                            {field.options.map((option, idx) => (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                    </div>
                  ))}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    style={{ marginTop: 10 }}
                    disabled={loading}
                  >
                    {loading ? "Uploading..." : "Download My Photos"}
                  </button>
                </div>
              </form>
            )}

            {loading && (
              <div className="mt-4">
                {uploadProgress < 100 ? (
                  <>
                    <p>
                      Processing your selfie...&nbsp;&nbsp;
                      <ClipLoader color={"#000000"} loading={true} size={20} />
                    </p>
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style={{ width: `${uploadProgress}%` }}
                        aria-valuenow={uploadProgress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {uploadProgress}%
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <p>
                      Comparing your selfie to other images...&nbsp;&nbsp;
                      <ClipLoader color={"#000000"} loading={true} size={20} />
                    </p>
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style={{ width: `${processingProgress}%` }}
                        aria-valuenow={processingProgress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {processingProgress}%
                      </div>
                    </div>
                    {processingProgress >= 100 && (
                      <p>Adding final touches...</p>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Event;
